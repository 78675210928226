<template>
  <div class="gw-roulette">
    <div class="gw-roulette_arrow" />
    <div class="gw-roulette_wrap">
      <div class="gw-roulette_inner">
        <div ref="roulette" class="gw-roulette_slider" :style="wheelAnimation">
          <div
            v-for="(item, i) in items"
            :key="i"
            :data-key="i"
            class="gw-roulette__item"
            :class="{ winner: stopped && i === 46 }"
          >
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  winner: {
    type: Object,
    default: () => {},
  },
  users: {
    type: Array,
    default: () => [],
  },
  code: {
    type: String,
    default: null,
  },
  delay: {
    type: Number,
    default: 0,
  },
});

const { $shuffleSeed } = useNuxtApp();
const roulette = ref();
const isReady = ref(false);
const stopped = ref(false);
const time = ref(10);
const generate = ref([]);
const items = ref([]);

function getRandom(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}
function generateRoulette() {
  const arr = [...props.users];
  arr.map((u) => generate.value.push(u));
  if (generate.value.length < 60) generateRoulette();
  else renderRoulette();
}
const renderRoulette = () => {
  const seed = `${props.code}_${props.winner.id}`;
  const arr = [];
  generate.value.map((el, i) => {
    const item = {};
    if (i !== 46) {
      item.id = $shuffleSeed.shuffle(generate.value, seed)[i].id;
      item.name = $shuffleSeed.shuffle(generate.value, seed)[i].name;
    } else {
      item.id = props.winner.id;
      item.name = props.winner.name;
    }
    arr.push(item);
  });
  items.value = arr;
  if (props.delay) time.value += props.delay;
  setTimeout(() => (isReady.value = true), 100);
  setTimeout(() => (stopped.value = true), Number((10 + props.delay) * 1000 + 500));
};

const wheelAnimation = computed(() => {
  if (isReady.value) {
    return {
      // transform: `translateX(-${getRandom(5612, 5722)}px)`,
      transform: `translateX(-${getRandom(5801, 5913)}px)`,
      transition: `all ${time.value}s cubic-bezier(0,0,0,1) 0s`,
    };
  } else {
    return {
      transform: `unset`,
      transition: "none",
    };
  }
});
generateRoulette();
</script>

<style lang="scss">
.gw-roulette {
  position: relative;
  overflow: hidden;

  &_wrap {
    background: var(--color-card);
    border-radius: 4px;
    text-align: center;
    padding: 24px 0 12px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 600px) {
      padding: 17px 0 2px 0;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      width: 80px;
      top: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        -270deg,
        var(--color-card) 0%,
        rgba(243, 243, 255, 0) 100%
      );
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      width: 80px;
      top: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(
        270deg,
        var(--color-card) 0%,
        rgba(243, 243, 255, 0) 100%
      );
    }
  }

  &_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &_inner {
    overflow: hidden;
    width: 100%;
    position: relative;
    display: flex;
    min-width: 150%;
    left: -25%;
    @media screen and (max-width: 600px) {
      transform: scale(0.75);
    }
  }

  &_slider {
    display: flex;
    gap: 4px;
    -webkit-perspective: 800px;
    left: 50%;
    position: relative;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 4px;

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-radius: 4px;
      width: 114px;
      min-width: 114px;
      height: 60px;
      text-align: center;
      font-weight: 500;
      line-height: 14px;
      font-size: 18px;
      padding: var(--padding);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(90deg, #7449e6 0.06%, #69a4ff 98.49%);
    }

    &:before {
      content: "";
      display: block;
      border: 2px solid orange;
      border-radius: 5px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: 0.3s;
    }

    &.winner {
      &:before {
        opacity: 1;
      }
    }
  }

  &_arrow {
    position: absolute;
    top: 0;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    width: 54px;
    height: 39px;
    background: url("~/assets/svg/x100-arrow.png") bottom center no-repeat;
    background-size: contain;
  }
}
</style>
