<template>
  <VueFinalModal name="giveaway" v-bind="$attrs" classes="modal-container" content-class="modal-content" @before-open="onBeforeOpen" @before-close="onBeforeClose">
    <div v-if="loading" class="box-modal gw" :class="{ oh: status === 3 }">
      <div class="box-modal_wrap">
        <div class="modal-header f">
          <div class="title">{{ $t("giveaway.giveaway") }}</div>
          <button class="btn_close" @click.prevent="close()">
            <img class="close" src="/icons/close.svg" alt="" />
          </button>
        </div>
        <div class="modal-body">
          <div class="gw_loading">
            <i class="i-loading" />
            <span>{{ $t("page.casino.game.loading") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="giveaway.rules" class="box-modal gw" :class="{ oh: status === 3 }">
      <div v-if="status === 1" class="box-modal_wrap">
        <div v-if="giveaway.user_id !== $auth.user?.id && !hasTelegram" class="gw-telegram">
          <img class="gw-telegram__img" src="@/assets/svg/alert-alt.svg?url" />
          <div class="gw-telegram__text">{{ $t("giveaway.giveaway_tg_text") }}</div>
          <VButton target="_blank" :href="botLink" class="gw-telegram__btn custom">{{ $t("giveaway.giveaway_tg_btn") }}</VButton>
        </div>

        <div class="modal-header f">
          <div class="title">{{ $t("giveaway.giveaway") }}</div>
          <button class="btn_close" @click.prevent="close()">
            <img class="close" src="/icons/close.svg" alt="" />
          </button>
        </div>
        <div class="modal-body">
          <div class="gw_wrap">
            <div class="gw-info">
              <div class="gw_card">
                <p>{{ $t("giveaway.for") }}</p>
                <b>{{ $t(`giveaway.for_${giveaway.rules.users}`) }}</b>
              </div>
              <div class="gw_card">
                <p>{{ $t("giveaway.winners_of") }}</p>
                <b>{{ giveaway.rules.winnerCount }}</b>
              </div>
              <div class="gw_card">
                <p>{{ $t("giveaway.amount") }}</p>
                <b>{{ Number(giveaway.amount).toFixed(2) }}₽</b>
              </div>
              <div class="gw_card">
                <p>{{ $t("giveaway.deposit_of") }}</p>
                <b>{{ Number(giveaway.rules.deposits) }}₽</b>
              </div>
            </div>
            <div class="gw_card gw-timer">
              <p>{{ $t("giveaway.starts_of") }}</p>
              <b v-if="timer !== '0'">{{ timeout }}</b>
              <b v-else>--:--</b>
            </div>
            <div v-if="$auth.loggedIn && giveaway.user_id === $auth.user.id" class="gw-option">
              <div class="gw-option_label">{{ $t("giveaway.ref_link") }}</div>
              <div class="gw-option_wrap gw_block">
                <p class="ell" :class="{ blur }">{{ giveaway.link }}</p>
                <div class="btn_toolbar f gap8">
                  <button class="bt-light" @click="blur = !blur">
                    <FontIcon :icon="blur ? 'show' : 'hide'" />
                  </button>
                  <button class="bt-light" @click="copy(giveaway.link)">
                    <FontIcon icon="copy" />
                  </button>
                </div>
              </div>
            </div>
            <div class="gw-table">
              <div class="gw-table_header f">
                <p>{{ $t("giveaway.name") }}</p>
                <p>{{ $t("giveaway.members_of") }} {{ meta.total }}</p>
              </div>
              <div class="gw-table_wrap" v-if="users.length" @scroll="onScrollTable">
                <div v-for="item in users" :key="item.id" class="user">
                  {{ item.name }}
                </div>
              </div>
            </div>

            <div class="gw-footer">
              <button v-if="!$auth.loggedIn" class="bt-orange w100p h50" @click="$vfm.show('auth')">
                <span>{{ $t("modal.auth.login") }}</span>
              </button>
              <button v-else-if="!joined && giveaway.user_id !== $auth.user.id" class="bt-orange w100p h50" @click="joinGiveaway()">
                <span>{{ $t("giveaway.participate") }}</span>
              </button>
              <button v-else-if="joined" class="bt-green w100p h50 no-click">
                <span>{{ $t("giveaway.joined") }}</span>
              </button>
              <button v-if="!users.length && $auth.loggedIn && giveaway.user_id === $auth.user.id" class="bt-light w100p h50" @click="cancelGiveaway()">
                <span>{{ $t("giveaway.cancel") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="status === 2" class="box-modal_wrap">
        <div class="modal-header f">
          <div class="title">{{ $t("giveaway.choose_winner") }}</div>
          <div class="btn_close" @click.prevent="close()">
            <img class="close" src="/icons/close.svg" alt="" />
          </div>
        </div>
        <div class="modal-body">
          <div class="gw_wrap">
            <div class="gw-info">
              <div class="gw_card">
                <p>{{ $t("giveaway.for") }}</p>
                <b>{{ $t(`giveaway.for_${giveaway.rules.users}`) }}</b>
              </div>
              <div class="gw_card">
                <p>{{ $t("giveaway.winners_of") }}</p>
                <b>{{ giveaway.rules.winnerCount }}</b>
              </div>
              <div class="gw_card">
                <p>{{ $t("giveaway.amount") }}</p>
                <b>{{ Number(giveaway.amount).toFixed(2) }}₽</b>
              </div>
              <div class="gw_card">
                <p>{{ $t("giveaway.members_of") }}</p>
                <b>{{ meta.total }}</b>
              </div>
            </div>
            <h3 class="w100p yellow tac" @click="status = 3">
              {{ $t("giveaway.choosing_winners") }}
            </h3>
            <div class="gw__grid">
              <Roulette v-for="(item, i) in giveaway.winners_users" :key="`roulette_${i}`" :winner="item" :delay="i" :code="code" :users="users" />
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="status === 3 && giveaway.giveaway_time_in_seconds < 60 * 60" class="box-modal_wrap">
        <div class="modal-body">
          <Winners :close="close" :users="giveaway.winners_users" :amount="amount" />
        </div>
      </div>
      <div v-else-if="status === 3 && giveaway.giveaway_time_in_seconds >= 60 * 60" class="box-modal gw">
        <div class="box-modal_wrap">
          <div class="modal-header f">
            <div class="title">{{ $t("giveaway.winners") }}</div>
            <button class="btn_close" @click.prevent="close">
              <img class="close" src="/icons/close.svg" alt="" />
            </button>
          </div>
          <div class="modal-body">
            <div class="gw_wrap">
              <div class="gw_pre-count">
                <img src="@/assets/img/giveres.svg?url" alt="" />
                <div class="winners">
                  {{ $t("giveaway.congrats") }}<br />
                  {{ $t("giveaway.give") }}
                </div>
              </div>
              <div class="gw_pre-title">{{ $t("giveaawy.title_winners") }}</div>
              <div class="gw-table">
                <div class="gw-table_header f">
                  <p>{{ $t("giveaway.name") }}</p>
                  <p>{{ $t("giveaway.members_of") }} {{ giveaway.count }}</p>
                </div>
                <div v-if="giveaway.winners_users.length" class="gw-table_wrap">
                  <div v-for="item in giveaway.winners_users" :key="item.id" class="user">
                    <span> {{ item.name }}</span>
                    <span> {{ amount }}₽</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="box-modal gw">
      <div class="box-modal_wrap">
        <div class="modal-header f">
          <div class="title">{{ $t("giveaway.giveaway") }}</div>
          <button class="btn_close" @click.prevent="close()">
            <img class="close" src="/icons/close.svg" alt="" />
          </button>
        </div>
        <div class="modal-body">
          <div class="gw_empty">
            <FontIcon icon="emojy-5" size="80" />
            <p>{{ $t("giveaway.not_found") }}</p>
          </div>
        </div>
      </div>
    </div>
  </VueFinalModal>
  <LazyModalGiveawayPrepare v-model="modals.giveaway_prepare" :users="meta.total" :giveaway="giveaway" :timer="timer" />
</template>

<script setup>
const modals = ref({
  giveaway_prepare: false,
  giveaway_winners: false,
  winner_active: false,
});
import { VueFinalModal } from "vue-final-modal";
import { $vfm } from "vue-final-modal";
import copier from "clipboard-copy";
// Components
import Roulette from "~/components/Modal/Giveaway/GiveawayRoulette.vue";
import Winners from "~/components/Modal/Giveaway/GiveawayWinners.vue";
import { convertMsToTime } from "~/utils";

const route = useRoute();
const router = useRouter();
const { $showToast } = useNuxtApp();
const { t } = useI18n();
const onBeforeOpen = (e) => {
  users.value = [];
  Object.assign(meta.value, {
    current_page: 1,
    last_page: 1,
    per_page: 20,
    total: 0,
  });
  const c = route.query.giveaway || e.ref.params.value.code;
  if (c) {
    code.value = c;
    initGiveaway();
  } else {
    loading.value = false;
  }
};
const onBeforeClose = (e) => {
  router.replace({ ...route, query: {} });
  if (subscribe.value) subscribe.value.unsubscribe();
  setTimeout(() => {
    giveaway.value = {};
    winners.value = [];
    timer.value = "0";
    status.value = 1;
    code.value = null;
    joined.value = false;
    loading.value = true;
    blur.value = true;
    modals.value.giveaway_prepare = false;
    modals.value.winner_active = false;
  }, 500);
};

function onScrollTable(e) {
  if (!e?.target || isLoading.value) return;
  const target = e.target;
  const top = target.scrollTop;
  const height = target.scrollHeight;
  const clientHeight = target.clientHeight;

  const loadingOffset = clientHeight * 0.9;

  if (top + clientHeight + loadingOffset <= height) return;

  const nextPage = meta.value.current_page + 1;
  if (nextPage > meta.value.last_page) return;

  getUsers(nextPage);
}

const giveaway = ref({
  winners_users: [],
});
const winners = ref([]);
const timer = ref("0");
const status = ref(1);
const code = ref(null);
const loading = ref(true);
const blur = ref(true);
const joined = ref(false);
const isLoading = ref(false);

const close = () => {
  $vfm.hide("giveaway");
};
const copy = (val) => {
  copier(val);
  $showToast(t("giveaway.copied"), "success");
};
const { $axios, $auth, $centrifuge, $config } = useNuxtApp();
const subscribe = ref(null);

const users = ref([]);
const meta = ref({
  current_page: 1,
  last_page: 1,
  per_page: 20,
  total: 0,
});

function getUsers(page = 1) {
  if (isLoading.value) return;
  isLoading.value = true;
  return $axios
    .$get(`/bonus/giveaways/${code.value}/users`, {
      params: {
        page,
      },
    })
    .then(({ response: { data, meta: _meta } }) => {
      meta.value = _meta;
      users.value.push(...data);
    })
    .finally(() => {
      isLoading.value = false;
    });
}

onBeforeUnmount(async () => {
  if (subscribe.value) await subscribe.value.unsubscribe();
});
const sub = () => {
  subscribe.value = $centrifuge.subscribe(`giveaway_${giveaway.value.id}`, (ctx, { data: { action, ...rest } }) => {
    if (action === "timer") {
      timer.value = rest.time;
      if (!modals.value.giveaway_prepare && rest.time <= 60 && giveaway.value.giveaway_time_in_seconds >= 60 * 60 && !modals.value.winner_active) {
        // && giveaway.value.rules.time >= 60 * 60\
        modals.value.winner_active = true;
        modals.value.giveaway_prepare = true;
      }
    } else if (action === "join") {
      if (users.value.length < meta.value.per_page) {
        users.value.push(rest.user);
      }
      meta.value.total++;
      meta.value.last_page = Math.ceil(meta.value.total / meta.value.per_page);

      if ($auth.loggedIn && $auth.user.id === rest.user.id) {
        joined.value = true;
      }
    } else if (action === "end") {
      giveaway.value.result = rest.winners;
      giveaway.value.winners_users = rest.users;
      if (!modals.value.giveaway_winners && giveaway.value.giveaway_time_in_seconds >= 60 * 60) {
        status.value = 3;
        if ($auth.loggedIn && rest.users.find((x) => x.id === $auth.user.id)) {
          const win = amount.value;
          const newBalance = Number($auth.user.money) + Number(win);
          $auth.user.money = newBalance.toString();
        }
      } else {
        status.value = 2;
        setTimeout(() => {
          status.value = 3;
          if ($auth.loggedIn && rest.users.find((x) => x.id === $auth.user.id)) {
            const win = amount.value;
            const newBalance = Number($auth.user.money) + Number(win);
            $auth.user.money = newBalance.toString();
          }
        }, 15000);
      }
    }
  });
};
const initGiveaway = async () => {
  await $axios
    .$get(`/bonus/giveaways/${code.value}`)
    .then(async ({ response }) => {
      if (response?.id) {
        Object.assign(giveaway.value, response);
        if (!$auth.loggedIn || $auth.user.id === response.user_id) {
          $axios
            .post(`ref/hit`, {
              refCode: response.user_id,
              referer: document.referrer,
              type: "giveaway",
            })
            .catch(() => {});

          if ($auth.loggedIn) {
            await $axios
              .get(`ref/activate`, {
                params: {
                  refCode: response.user_id,
                },
              })
              .catch(() => {});
          }
        }
        await getUsers(1);
        if (!response.users.length) timer.value = response.rules?.time;
        sub();
        if (response.winners_users?.length) {
          giveaway.value.result = response.winners;
          status.value = 3;
        } else if (!response.active && !response.users.length) {
          giveaway.value = {};
        } else if ($auth.loggedIn && response.users.find((el) => el.id === $auth.user.id)) {
          joined.value = true;
        }
        if (response.active && !$auth.loggedIn) {
          const refCode = useCookie("refCode", {
            maxAge: 60 * 60,
          });
          refCode.value = response.user_id;
        }
      }
    })
    .finally(() => {
      loading.value = false;
    });
};
const joinGiveaway = async () => {
  await $axios.$put(`/bonus/giveaways/${code.value}`);
};
const cancelGiveaway = async () => {
  await $axios.$patch(`/bonus/giveaways/${code.value}`).then(({ response }) => {
    if (response.balance) $auth.user.money = response.balance;
    $showToast(t("giveaway.canceled"), "success");
    setTimeout(() => {
      $vfm.show("giveaway_create");
    }, 300);
    $vfm.hide("giveaway");
  });
};

const botLink = computed(() => {
  const token = $auth.user?.telegram_bind_code;
  if (!token) return t("modal.telegram.refresh");
  return `https://t.me/${$config.public.TG_BOT}?start=${token}`;
});
const amount = computed(() => (giveaway.value.amount / (meta.value.total > giveaway.value.rules.winnerCount ? giveaway.value.rules.winnerCount : meta.value.total)).toFixed(2));
const hasTelegram = computed(() => {
  if (!$auth.loggedIn) return true;
  return !!$auth.user.socials.find((x) => x.provider === "tg");
});
const timeout = computed(() => convertMsToTime(parseFloat(timer.value) * 1000, giveaway.value.giveaway_time_in_seconds >= 60 * 60 ? "DD-HH-MM-SS" : "MM-SS").join(":"));
</script>

<style lang="scss">
@import "@/assets/fonts/GetVoIP/index.scss";

.gw-telegram {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(6px);
  border-radius: var(--b-radius);

  padding: 27px;

  &__img {
    margin-bottom: 12px;
    width: 48px;
    height: auto;
  }

  &__text {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: var(--padding);
    text-align: center;
  }
  &__btn {
    width: 100%;
    // background: var(--Linear, linear-gradient(90deg, #7449e6 0.06%, #69a4ff 98.49%)) !important;
    // color: #ffffff !important;
  }
}

@include dark {
  .gw-telegram {
    background: rgba(0, 0, 0, 0.1);
  }
}

.gw_pre-title {
  font-style: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 12px;
  margin-top: 20px;
  text-align: center;
}

.gw_pre-count {
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  .winners {
    color: #fff;
    text-edge: cap;
    text-shadow: 0px 1.668393850326538px 0px #574094, 0px 0.834196925163269px 2.5025906562805176px #544492;
    font-family: GetVoIP Grotesque Bold;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 115%;
    /* 20.7px */
    letter-spacing: 0.36px;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}
</style>
